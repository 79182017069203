@import '../base/base-theme';
@import '@slb-dls/angular-material/assets/styles/theming/dls-palette';
@import './variables';

@include theme-base($dls-app-light-theme, $app-theme-config);

.date-filters-content,
.vi_camera-profile_alerts-container,
.date-fields {
    background: linear-gradient(34.37deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.65) 100%);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15) !important;
    border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    color: #22263d;
}

.dls-sidenav-content {
    color: #22263d;
}

.icon-button {
    background: #fff;
    color: #22263d;
}

.mat-mdc-dialog-container .mdc-dialog__container,
.mat-mdc-dialog-container .mdc-dialog__container .mdc-dialog__surface {
    .icon-button {
        background: unset;
        color: #22263d;
    }
}

.camera-table-container {
    background: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15) !important;
    border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    color: #22263d;
}

.mat-mdc-table {
    background-color: #fff !important;
}

.risk-events-header {
    color: #22263d;
}

.dls-date-time-picker-content .dls-date-time-picker-container {
    background-color: #fff;
}